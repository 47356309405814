body {
  font-style: "Roboto";
}

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none !important;
}

html,
body,
#root {
  height: 100% !important;
}

body {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
